import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["fileInput", "placeholder", "fileName"]
  static classes = ["hidden"]

  connect() {
    this._toggleFileNameVisibility()
  }

  filesChange(dropzoneEvent) {
    const files = dropzoneEvent.detail.files

    if (files.length > 0) {
      const dataTransfer = new DataTransfer()
      for (const file of files) { dataTransfer.items.add(file) }
      this.fileInputTarget.files = dataTransfer.files

      this._toggleFileNameVisibility()
    }
  }

  _toggleFileNameVisibility() {
    if (this.fileInputTarget.files.length === 0) {
      this.fileNameTarget.classList.add(this.hiddenClass)
      this.placeholderTarget.classList.remove(this.hiddenClass)
    } else {
      let fileName = this.fileInputTarget.files[0].name
      if (this.fileInputTarget.files.length > 1) {
        fileName = fileName + ", ..."
      }

      this.fileNameTarget.innerHTML = fileName

      this.placeholderTarget.classList.add(this.hiddenClass)
      this.fileNameTarget.classList.remove(this.hiddenClass)
    }
  }
}
